import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { selectPatchTransactionStatus, selectTransaction } from 'store/transactions/selectors';
import { getTransaction, patchTransaction } from 'store/transactions/actions';
import { ACTION_STATUSES, PATCH_STATUSES, TRACK_EVENTS, TRACK_EVENT_TYPES } from 'shared/consts';
import { useLocation } from 'react-router';
import { useSearchParams } from 'hooks/use-search-params.hook';
import qs from 'qs';
import { replace } from 'connected-react-router';
import { track } from '@hqo/web-tracking';
import { getCurrentAvailableService } from 'store/availableServices/actions';
import { useOwnerValues } from 'hooks/use-owner-values.hook';
import { useParams } from 'react-router-dom';
import { ProviderParams } from 'shared/types';

interface UseCancelBookingReturnValues {
  vendorName: string;
  onCancelButtonClick: VoidFunction;
  isEventPaid: boolean;
  ctaLabel: string;
}

// eslint-disable-next-line max-lines-per-function
export const useCancelBooking = (): UseCancelBookingReturnValues => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const transaction = useSelector(selectTransaction);
  const patchTransactionStatus = useSelector(selectPatchTransactionStatus);
  const { confirmCancel, ...restQueryParams } = useSearchParams();
  const queryString = qs.stringify({ ...restQueryParams });
  const { ownerType, ownerUuid } = useOwnerValues();
  const { companyUuid, serviceUuid } = useParams<ProviderParams>();

  const { id, uuid, details } = transaction || {};
  const vendorName = details?.service?.name;

  useEffect(() => {
    if (patchTransactionStatus === ACTION_STATUSES.FULFILLED) {
      dispatch(getCurrentAvailableService.request({ ownerType, ownerUuid, companyUuid, serviceUuid }));
      dispatch(getTransaction.request(uuid));
      dispatch(replace(`${pathname}?${queryString}`));
    }
  }, [dispatch, patchTransactionStatus, pathname, uuid, queryString, confirmCancel]);

  const onCancelButtonClick = useCallback(() => {
    track(
      TRACK_EVENTS.SB_CANCEL_CONFIRMATION_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
      },
      { sendToHqoTracking: true },
    );

    dispatch(
      patchTransaction.request({
        transactionId: id?.toString(),
        status: PATCH_STATUSES.CANCELLED,
      }),
    );
  }, [dispatch, id]);

  return {
    vendorName,
    onCancelButtonClick,
    isEventPaid: !!transaction?.total_price,
    ctaLabel: details.service.cta_label ?? 'Book',
  };
};
